.z1 {
  z-index: 1;
}
.z400 {
  z-index: 400;
}
.vw75 {
  width: 75vw;
}
.vh100 {
  height: 100vh;
}
#nav-icon {
  width: 20px;
  height: 16px;
  position: relative;
  margin: 5px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
}
#nav-icon span {
  border-radius: 2px;
  display: block;
  margin-bottom: 4px;
  height: 3px;
  width: 100%;
  background: #333333;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
#nav-icon span:nth-child(1) {
  top: 0px;
}
#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 18px;
}
#nav-icon span:nth-child(4) {
  top: 36px;
}
#nav-icon.open {
  padding-top: 7px;
}
#nav-icon.open span {
  margin-bottom: -3px;
}
#nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.check-container {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark {
  position: absolute;
  top: 3px;
  left: 1px;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border: 1px solid #0099ff;
  border-radius: 3px;
}
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.check-container input:checked ~ .checkmark {
  background-color: #0099ff;
}
.check-container input:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.check-container .checkmark:after {
  left: 4px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.selectItem {
  width: 0;
}
.selectLabel {
  min-width: 85px;
}
.radio {
  margin: 0px 1px;
  display: block;
  cursor: pointer;
}
.radio input {
  display: none;
}
.radio input + span {
  line-height: 16px;
  height: 16px;
  padding-left: 16px;
  display: block;
  position: relative;
}
.radio input + span:not(:empty) {
  padding-left: 24px;
}
.radio input + span:before,
.radio input + span:after {
  content: '';
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 0;
  position: absolute;
}
.radio input + span:before {
  background: #D1D7E3;
  transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.radio input + span:after {
  background: #fff;
  transform: scale(0.78);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}
.radio input:checked + span:before {
  transform: scale(1.04);
  background: #0099ff;
}
.radio input:checked + span:after {
  transform: scale(0.4);
  transition: transform .3s ease;
}
.radio:hover input + span:before {
  transform: scale(0.92);
}
.radio:hover input + span:after {
  transform: scale(0.74);
}
.radio:hover input:checked + span:after {
  transform: scale(0.4);
}
