* {
  box-sizing: border-box;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  height: 100vh;
  margin: 0;
  color: #333333;
  background-color: #FFFFFF;
  overflow-y: hidden;
}
.displayFont {
  font-family: 'Montserrat', sans-serif;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
}
#ndw-map {
  width: 100vw;
}
#ndw-map .ndw-popup {
  margin-bottom: 10px;
}
#ndw-map .ndw-popup .popup-title {
  font-size: 16px;
}
#ndw-map .ndw-popup .popup-text {
  font-size: 12px;
}
#ndw-map .ndw-popup .popup-label {
  font-size: 12px;
  color: darkgrey;
}
#ndw-map .ndw-popup .speed-indicator {
  font-size: 11px;
}
#ndw-map .ndw-popup .speed-indicator b {
  font-size: 18px;
}
#mqttState {
  font-size: 14px;
  position: absolute;
  top: 0px;
  z-index: 401;
  padding: 5px 10px;
  background-color: #FFFFFF;
}
#ndw-info-panel {
  min-width: 300px;
  max-width: 330px;
  color: #333333;
  background-color: #F3F3F3;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 600;
}
#ndw-info-panel .title {
  font-size: 28px;
  font-weight: bold;
  display: block;
}
#ndw-info-panel .subtitle {
  display: block;
  font-size: 20px;
  color: #333333;
  margin-bottom: 30px;
}
#ndw-info-panel .label {
  font-size: 20px;
}
#ndw-info-panel .layerList {
  height: 150px;
  justify-content: space-between;
}
#ndw-info-panel .layerColumn {
  min-width: 130px;
}
.footer {
  display: none;
  position: absolute;
  bottom: -5px;
  width: 100vw;
}
.footer .debug-console-wrapper {
  display: none;
  z-index: 600;
}
.footer .debug-console-wrapper textarea {
  padding: 10px;
  color: #FFFFFF;
  background-color: black;
  width: 100vw;
  height: 200px;
  border: 0;
}
.footer .console-button-wrapper {
  margin-right: 360px;
}
.toggleMenu {
  position: absolute;
  z-index: 601;
  right: 10px;
  top: 10px;
}
.toggleMenu button {
  padding: 2px 5px;
}
.mapSelector {
  background-color: #FAFAFA;
  border-radius: 20px;
  padding: 0px 10px;
}
.ndw-speed-idle {
  color: red;
}
.ndw-speed-low {
  color: orange;
}
.ndw-speed-medium {
  color: #cccc00;
}
.ndw-speed-high {
  color: green;
}
.ndw-no-ov-bike-counter {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.leaflet-marker-icon.my-awesome-bus.leaflet-zoom-animated.leaflet-interactive {
  color: aliceblue;
  text-align: center;
  background-image: url(/images/bus.png);
  background-size: 15px;
  width: 15px;
}
.leaflet-control-container {
  position: relative;
  top: 25px;
}
.leaflet-control-attribution {
  display: none;
}
.leaflet-div-icon.air-pollution-div-icon {
  background: transparent;
  border: none;
}
.air-pollution-info-window span.infolabel {
  display: inline-block;
  width: 5em;
  text-align: right;
  color: #000;
  padding-right: 0.5em;
}
.air-pollution-info-window small {
  color: #9e9e9e;
}
.hidden {
  display: none;
}
.railsense-delays {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}
